import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [iconColor, setIconColor] = useState('black');

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const throttle = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (!timeoutId) {
        func(...args);
        timeoutId = setTimeout(() => {
          timeoutId = null;
        }, delay);
      }
    };
  };

  useEffect(() => {
    const handleScroll = throttle(toggleVisibility, 200);

    window.addEventListener('scroll', handleScroll);

    toggleVisibility();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleMouseEnter = () => {
    setIconColor('#1DA689');
  };

  const handleMouseLeave = () => {
    setIconColor('black');
  };

  return (
    <>
      {isVisible && (
        <StyledButton
          onClick={scrollToTop}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <mask
              id="mask0_457_6212"
              style={{maskType: 'alpha'}}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="32"
              height="32">
              <rect width="32" height="32" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_457_6212)">
              <path
                d="M7 14.5L17.5 4V28"
                stroke={iconColor}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </StyledButton>
      )}
    </>
  );
};

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 14px;
  right: 13px;
  background-color: #fff;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  z-index: 100;
  &:hover {
    border: 1px solid #1fa689;
  }
`;

export default ScrollToTopButton;
