import React, {useState, Fragment} from 'react';
import styled from 'styled-components';
import {ExpandLess, ExpandMore} from '@styled-icons/material';

export default function Expander(props) {
  const {
    expand = true,
    title,
    onClick,
    ExpandMoreIcon = () => <ExpandMore size={28} color="#5a5a5a" />,
    ExpandLessIcon = () => <ExpandLess size={28} color="#5a5a5a" />,
    containerStyle = {},
    titleStyle = {},
    titleMobileStyle = {},
    containerMobileStyle,
    contentStyle = {},
    closable = true,
    children,
  } = props;
  const [open, setOpen] = useState(expand);

  let extraProps = {
    ...(onClick && {onClick}),
  };

  if (!children) {
    return (
      <SectionExpand
        style={containerStyle}
        containerMobileStyle={containerMobileStyle}
        titleMobileStyle={titleMobileStyle}
        {...extraProps}>
        {typeof title === 'string' ? (
          <div style={titleStyle}>{title}</div>
        ) : (
          title
        )}
      </SectionExpand>
    );
  }

  return (
    <>
      <SectionExpand
        expand={open}
        onClick={() => setOpen((value) => (closable ? !value : true))}
        style={containerStyle}
        containerMobileStyle={containerMobileStyle}
        titleMobileStyle={titleMobileStyle}>
        {typeof title === 'string' ? (
          <div style={{flex: 1, ...titleStyle}}>{title}</div>
        ) : (
          <div style={{flex: 1}}>{title}</div>
        )}
        {!closable ? null : open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </SectionExpand>

      {open && (
        <div style={{padding: '10px 0', ...contentStyle}}>{children}</div>
      )}
    </>
  );
}

const SectionExpand = styled.div`
  padding: 10px 0;
  margin-bottom: ${(props) => (props.expand ? '10px' : '20px')};
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    ${(props) => props.containerMobileStyle}
    & > div:nth-child(1) {
      ${(props) => props.titleMobileStyle}
    }
  }
`;
