import React, {useState} from 'react';
import styled from 'styled-components';
import {Bell} from '@styled-icons/evaicons-solid';
import {Close} from '@styled-icons/material-rounded';
const appConfig = require('../../src/data.json');

function FixedBar(props) {
  const {
    Icon = () => <Bell size={20} color="white" />,
    children,
    bg,
    color,
  } = props;
  const [visible, setVisible] = useState(true);
  return (
    visible && (
      <StyledFixedBar bg={bg} color={color}>
        <div>
          <Icon />
          <p>{children}</p>
          <CloseBtn color={color} onClick={() => setVisible(false)} />
        </div>
      </StyledFixedBar>
    )
  );
}

const StyledFixedBar = styled.div`
  width: 100%;
  background-color: ${(props) => props.bg || appConfig.colors.main};
  padding: 16px 0;
  & > div {
    display: flex;
    align-items: center;
    max-width: 1240px;
    margin: 0 auto;
    svg {
      flex-shrink: 0;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: ${(props) => props.color || 'white'};
      flex: auto;
      margin: 0 0 0 8px;
    }
  }
  @media only screen and (max-width: 1240px) {
    & > div {
      padding: 0 20px;
    }
  }
  @media only screen and (max-width: ${appConfig.breakpoints.lg}px) {
    & > div {
      p {
        font-size: 14px;
      }
    }
  }
`;

const CloseBtn = styled(Close)`
  width: 16px;
  color: ${(props) => props.color || 'white'};
  cursor: pointer;
`;

export default FixedBar;
