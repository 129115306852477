import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react';
import {Context} from '../AppContext';
import {Modal} from 'antd';
import {Close} from 'styled-icons/material';
import styled from 'styled-components';

const initialModal = {
  content: null,
  title: false,
  width: '400px',
};

function ModalContainer(props) {
  const app = useContext(Context);
  const [modal, setModal] = useState(initialModal);

  useEffect(() => {
    if (!app.state.modalContent) {
      setModal(initialModal);
    } else {
      setModal(app.state.modalContent);
    }
  }, [app.state.modalContent]);

  return (
    <StyledModal
      visible={!!modal.content}
      onCancel={() => app.actions.setModal()}
      title={modal.title}
      footer={false}
      closeIcon={
        <Close
          style={{position: 'absolute', right: 20, top: 20}}
          size={20}
          color="#000"
        />
      }
      zIndex={15}
      width={modal.width}>
      {modal.content}
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 10px 30px;
    background-color: #fafafa;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      color: #5a5a5a;
      line-height: 1.58;
    }
  }
  .ant-modal-body {
    padding: 20px 30px;
  }
  .ant-modal-footer {
    padding: 15px 30px;
    border-top: none;
  }
`;

export default ModalContainer;
