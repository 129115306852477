import {message} from 'antd';

class ErrBase extends Error {}
class ErrNoSpec extends ErrBase {}
class ErrCartNotAvailable extends ErrBase {}
class ErrParamTypeNotAvailable extends ErrBase {}
class ErrInvoiceFormat extends ErrBase {}
class ErrCheckoutForm extends ErrBase {}
class ErrCoupon extends ErrBase {}
class ErrUploadFile extends ErrBase {}
class ErrProfileFormat extends ErrBase {}
class ErrCustomOrder extends ErrBase {}
class ErrRegister extends ErrBase {}
class ErrForgetPwd extends ErrBase {}
class ErrReturnApp extends ErrBase {}
class ErrOrder extends ErrBase {}
class ErrUpgrade extends ErrBase {}
class ErrLogin extends ErrBase {}

function errorHandler(ex, defaultMsg = '發生錯誤') {
  console.warn(ex);
  if (ex instanceof ErrBase) {
    message.error(ex.message);
  } else if (ex.error === 'items_version_expired') {
    message.error('商品已更新，將會購物車清除舊版商品，請重新加入購物車。');
  } else if (ex.error === 'period_item_error') {
    message.error('您的購物車同時包含：訂閱制商品與一般商品，請拆單分開下單。');
  } else {
    message.error(defaultMsg);
  }
}

export {
  ErrBase,
  ErrNoSpec,
  ErrCartNotAvailable,
  ErrParamTypeNotAvailable,
  ErrInvoiceFormat,
  ErrCheckoutForm,
  ErrCoupon,
  ErrUploadFile,
  ErrProfileFormat,
  ErrCustomOrder,
  ErrRegister,
  ErrForgetPwd,
  ErrReturnApp,
  ErrOrder,
  ErrUpgrade,
  ErrLogin,
  errorHandler,
};
