import React from 'react';
import styled from 'styled-components';
const config = require('../data.json');

const sizes = {
  lg: '20px 0',
  md: '10px 0',
  sm: '5px 0',
};
const positions = {
  left: 'left',
  center: 'center',
  right: 'right',
};

export default function Divider(props) {
  const {
    paddingSize = 'md',
    margin,
    position = positions.center,
    color,
    borderWidth,
  } = props;

  const marginValue = margin || sizes[paddingSize] || sizes.md;

  return props.children ? (
    <Wrapper>
      {position !== positions.left && (
        <Line margin={marginValue} color={color} borderWidth={borderWidth} />
      )}
      <div style={{margin: '0 10px', whiteSpace: 'nowrap'}}>
        {props.children}
      </div>
      {position !== positions.right && (
        <Line margin={marginValue} color={color} borderWidth={borderWidth} />
      )}
    </Wrapper>
  ) : (
    <Line margin={marginValue} color={color} borderWidth={borderWidth} />
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  height: ${(props) => props.borderWidth || 1}px;
  background-color: ${(props) => props.color || config.colors.border};
  margin: ${(props) => props.margin};
`;
