import React from 'react';
import styled from 'styled-components';
const config = require('../data.json');

const sizes = {
  xxl: config.fontSize.xxl + 'px',
  xl: config.fontSize.xl + 'px',
  lg: config.fontSize.lg + 'px',
  md: config.fontSize.md + 'px',
  sm: config.fontSize.sm + 'px',
  xs: config.fontSize.xs + 'px',
};

const TextWrapper = styled.div`
  font-size: ${(props) => sizes[props.size]};
  color: ${(props) => props.color || config.colors.text};
  font-weight: ${(props) => props.weight};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  ${(props) => props.css};
`;

export default function Text(props) {
  const {
    size = 'md',
    color = '',
    weight = null,
    inline = false,
    style,
    css = {},
    ...rest
  } = props;

  return (
    <TextWrapper
      size={size}
      color={color}
      weight={weight}
      inline={inline}
      style={style}
      css={css}
      {...rest}>
      {props.children}
    </TextWrapper>
  );
}
