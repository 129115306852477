import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import {Expander, Text} from '../Widgets';
import {Link} from 'gatsby';
import {Add, ExpandLess} from '@styled-icons/material';
import SocialLinks from './SocialLinks';
import Image from './Image';
import CopyRightLinks from '../Components/CopyRightLinks';
const config = require('../data.json');

const {footer} = config;

function withPhoneClick(tel) {
  try {
    let _tel = tel.split('-').join('');
    if (_tel[0] !== '+' && _tel[0] !== '0' && _tel[0] !== '8') {
      throw 'wrong format';
    }
    return (
      <a
        aria-label={'tel：' + _tel}
        href={`tel:${_tel}`}
        style={{color: 'black', textDecoration: 'none'}}>
        {tel}
      </a>
    );
  } catch (e) {
    return tel;
  }
}

function withMailClick(mail) {
  return (
    <a
      aria-label={'mailto：' + mail}
      href={`mailto:${mail}`}
      style={{color: 'black', textDecoration: 'none'}}>
      {mail}
    </a>
  );
}

function withUrlClick(text, url) {
  if (url === '' || !url) {
    return text;
  } else {
    return (
      <a
        aria-label={text + '-' + url}
        href={url}
        target="_blank"
        style={{color: 'black', textDecoration: 'none'}}
        rel="noreferrer">
        {text}
      </a>
    );
  }
}

const Label = ({children, size = 'xs', color}) => (
  <Text size={size} color={color} style={{marginBottom: 12}} weight="500">
    {children}
  </Text>
);
const Value = ({children}) => (
  <Text
    size="xs"
    color="#505050"
    style={{marginBottom: 12, whiteSpace: 'break-spaces'}}>
    {children}
  </Text>
);

function Block({block}) {
  return (
    <Fragment>
      <Label color={footer.labelColor} size={footer.labelFontSize}>
        {block.label}
      </Label>
      {block.items.map((item, idx) => (
        <Value key={idx}>
          {item.url ? (
            <a
              href={item.url}
              rel="noopener noreferrer"
              style={{color: '#505050'}}>
              {item.text}
            </a>
          ) : (
            item.text
          )}
        </Value>
      ))}
    </Fragment>
  );
}

export default function Footer(props) {
  return (
    <Wrapper>
      <Content>
        <Row gutter={[16, 16]}>
          <Col md={4} sm={8} xs={24}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Image
                src="/images/logo-word.png"
                alt="logo"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: 250,
                  marginBottom: 10,
                }}
                width="120"
                height="45"
              />

              <SocialLinks />
            </div>
          </Col>
          <Col md={11} sm={16} xs={24}>
            <div style={{padding: '0 30px'}}>
              <Block block={footer.blocks[0]} />
            </div>
          </Col>
          <Col md={2} sm={4} xs={0}>
            <Block block={footer.blocks[1]} />
          </Col>
          <Col md={2} sm={4} xs={0}>
            <Block block={footer.blocks[2]} />
          </Col>
          <Col md={5} sm={16} xs={0}>
            <Block block={footer.blocks[3]} />
          </Col>
          <Col md={0} sm={0} xs={24}>
            {footer.blocks
              .filter((_, index) => index > 0)
              .map((block, idx) => (
                <Expander
                  key={idx}
                  title={block.label}
                  ExpandMoreIcon={() => <Add size={12} color="#5a5a5a" />}
                  ExpandLessIcon={() => (
                    <ExpandLess size={12} color="#5a5a5a" />
                  )}
                  titleStyle={{fontSize: 16}}
                  contentStyle={{padding: '10px 20px'}}>
                  {block.items.map((item, idx) => (
                    <Value key={idx}>{item.text}</Value>
                  ))}
                </Expander>
              ))}
          </Col>
        </Row>
      </Content>

      <CopyrightSection />
    </Wrapper>
  );
}

const CopyrightSection = () => {
  const {width} = useWindowDimensions();

  if (width > config.breakpoints.sm) {
    return (
      <Copyright css="height:42px;display:flex;justify-content:center;align-items:center;">
        <Text size="s" color={config.footer.copyrightColor}>
          {config.information.copyright} |&nbsp;
          <div
            style={{
              display: 'inline',
              color: config.footer.copyrightColor,
              marginRight: 5,
              fontSize: 10,
            }}>
            Powered By
          </div>
          <a
            style={{
              display: 'inline',
              fontSize: 12,
              color: config.footer.copyrightColor,
            }}
            href="https://www.eculture.tech/"
            target="_blank"
            rel="noreferrer">
            奕果雲端數位 EcultureTech
          </a>
        </Text>
        <CopyRightLinks />
      </Copyright>
    );
  }

  return (
    <Copyright>
      <Text size="s" color={config.footer.copyrightColor}>
        {config.information.copyright}
      </Text>
      <div style={{verticalAlign: 'middle'}}>
        <div
          style={{
            display: 'inline',
            color: config.footer.copyrightColor,
            marginRight: 5,
            fontSize: 10,
          }}>
          Powered By
        </div>
        <a
          style={{
            display: 'inline',
            fontSize: 12,
            color: config.footer.copyrightColor,
          }}
          href="https://www.eculture.tech/"
          target="_blank"
          rel="noreferrer">
          奕果雲端數位 EcultureTech
        </a>
      </div>
    </Copyright>
  );
};

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions(),
  );

  function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} =
      typeof window !== 'undefined'
        ? window
        : {
            innerWidth: 1024,
            innerHeight: 800,
          };
    return {
      width,
      height,
    };
  }

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return windowDimensions;
}

const Wrapper = styled.div`
  background-color: ${config.footer.backgroundColor};
  min-height: 250px;
  display: flex;
  flex-direction: column;
  box-shadow: ${config.footer.boxShadow ? '0px -2px 8px -4px #555' : 'none'};
  z-index: 1;
`;

const Content = styled.div`
  max-width: ${config.maxContentWidth}px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0 20px 0;
  flex: 1;
  color: ${config.colors.textSecond};
  font-size: 12px;

  & > p {
  }

  & .nav-item {
    margin-bottom: 12px;
    color: ${config.colors.textSecond};
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  & > .title {
    font-weight: 500;
  }

  @media screen and (max-width: ${config.maxContentWidth}px) {
    padding: 40px 20px 20px 20px;
  }

  @media screen and (max-width: ${config.breakpoints.sm}px) {
    padding: 40px 20px 20px 20px;
  }

  ${(props) => props.css || ''};
`;

const Copyright = styled.div`
  padding: 15px;
  background-color: ${config.footer.copyrightBackgroundColor ||
  config.colors.main};
  text-align: center;
  align-self: stretch;
`;
